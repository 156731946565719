import axios from "@/plugins/axios.js";

/**
 * 学校资源管理列表查询
 * @return {data}
 */
export function queryCollectiveList(data) {
  return axios.post("/resPackageManage/queryCollectiveList", data);
}

/**
 * 学校资源删除
 * @param {}
 * @returns
 */
export function delRecord(data) {
  return axios.get(`/resPackageManage/delSchoolResources?id=${data}`);
}
/**
 * 学校资源管理列表查询
 * @return {data}
 */
export function updateOnlineState(data) {
  return axios.post("/resourceManage/updateOnlineState", data);
}
/**
 * 跨校共享
 * @return {data}
 */
export function platformShare(data) {
  return axios.post("/resourceManage/platformShare", data);
}
