import { getCourseAuth } from "@/api/home";

export default {
  methods: {
    async handleGetGradeAndSubject() {
      await getCourseAuth().then((res) => {
        if (res.data?.length) {
          res.data.forEach((item) => {
            if (item.code === "gradeId") {
              // 年级选项
              this.gradeOptions = item.children.map(({ id, cnName }) => ({
                id,
                name: cnName,
              }));
            } else if (item.code === "subjectId") {
              // 科目选项
              this.subjectOptions = item.children.map(({ id, cnName }) => ({
                id,
                name: cnName,
              }));
            }
          });
        }
      });
    },
  },
};
