<template>
  <div class="manageOption">
    <el-dialog
      :visible.sync="visible"
      :title="title || $t('el.schoolResourceManage.auditRecords')"
      width="570px"
      center
    >
      <div class="from">
        <div v-if="manageList && manageList.length > 0">
          <div class="item" v-for="(item, index) in manageList" :key="index">
            <div class="li">
              <span class="span">{{ item.operatorName }}</span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="li">
              <span class="span">{{ $t("el.common.operate") }}</span>
              <span class="doSomething">{{
                operateTypeText(item.operateType)
              }}</span>
            </div>
            <div class="li" v-if="showRemark">
              <span class="span">{{ $t("el.schoolResourceManage.note") }}</span>
              <span>{{
                item.remarks || $t("el.schoolResourceManage.no")
              }}</span>
            </div>
          </div>
        </div>
        <p class="no-data" v-if="!manageList.length">
          {{
            showRemark ? $t("el.common.NoLogging") : $t("el.common.NoRecord")
          }}
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="cancel"
          @click="closeContentDialog"
          size="small"
          >{{ $t("el.schoolResourceManage.shutDown") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { getResourceRecordList } from "@/api/teacher/index";

export default {
  props: {
    showRemark: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
      // default: this.$t("el.schoolResourceManage.auditRecords"),
    },
  },
  data() {
    return {
      manageList: [],
      visible: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  methods: {
    init(Info) {
      this.visible = true;
      this.manageList = Info;
    },
    operateTypeText(type) {
      let text = "";
      switch (type) {
        case 0:
          text = this.$t("el.schoolResourceManage.crossSchoolShared");
          break;
        case 1:
          text = this.$t("el.schoolResourceManage.approved");
          break;
        case 2:
          text = this.$t("el.schoolResourceManage.returnToModify");
          break;
        case 3:
          text = this.$t("el.schoolResourceManage.approvedAndOnline");
          break;
        case 4:
          text = this.$t("el.schoolResourceManage.online");
          break;
        case 5:
          text = this.$t("el.schoolResourceManage.offline");
          break;
        case 6:
          text = this.$t("el.schoolResourceManage.Highlight");
          break;
        case 7:
          text = this.$t("el.schoolResourceManage.CancelHighlight");
          break;
        case 8:
          text = this.$t("el.schoolResourceManage.AutoReturn");
          break;
        case 9:
          text = this.$t("el.schoolResourceManage.submitAudit");
      }
      return text;
    },
    closeContentDialog() {
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.clear {
  clear: both;
}
/deep/.el-dialog__header {
  padding-top: 26px;
  padding-bottom: 0;
}
/deep/ .el-dialog__body {
  padding: 7px 0 0 0;
  max-height: 350px;
  overflow: auto;
}
/deep/ .el-dialog__footer {
  padding-top: 16px;
}
.radio {
  margin-bottom: 15px;
  margin-right: 52px;
}
.from {
  height: 240px;
  overflow: auto;
  .item {
    border-bottom: 1px solid rgba(234, 234, 234, 1);
    padding-bottom: 8px;
    padding-top: 15px;
    padding-left: 40px;
    padding-right: 30px;
    .li {
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 400;
      color: rgba(48, 49, 51, 1);
      line-height: 22px;
      display: flex;
      align-items: center;
      .span {
        min-width: 60px;
        margin-right: 16px;
        display: flex;
        justify-content: flex-start;
      }
    }
    // .li:first-child {
    //   margin-bottom: 15px;
    // }
  }
  .item:last-child {
    border-bottom: none;
  }
}
.no-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 150px auto;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(48, 49, 51, 1);
  line-height: 22px;
}
.doSomething {
  color: #6049ff;
}
</style>
