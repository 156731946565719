import axios from "@/plugins/axios.js";

/**
 * 审核管理列表
 * @return {data}
 */
export function getCheckList(data) {
  return axios.post("/resPackageManage/queryCheckList", data);
}
/**
 * 审核确认
 * @return {data}
 */
export function updateCheckState(data) {
  return axios.post("/resourceManage/updateCheckState", data);
}
/**
 * 审核记录
 * @return {Promise}
 */
export function queryAuditRecordsList(data) {
  return axios.post("/pkgOprRecord/queryList", data);
}
/**
 * 文件下载次数加1
 * @return {Promise}
 */
export function getDownLoadCount(data) {
  return axios.get("/resourceManage/addDownLoadCount", {
    params: data,
  });
}
